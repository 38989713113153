import React, { useState } from 'react';
import { graphql } from 'gatsby';

import PageLayout from "../../components/layout/PageLayout";
import Seo from '../../components/seo';
import NewsItem from '../../components/templates/NewsPageTemplate/NewsItem';
import Section from '../../components/layout/Section';

const SingleNewsPage = ({ data, location }) => {
    const [isPageScrollLocked, setIsPageScrollLocked] = useState(false);

    const { prismicNewsAndEvents } = data;

    const newsItem = {
        uid: '',
        first_publication_date: prismicNewsAndEvents.first_publication_date,
        news_title: prismicNewsAndEvents.data.news_title.text,
        news_text: prismicNewsAndEvents.data.news_text.text,
        news_image: prismicNewsAndEvents.data.news_main_image,
        news_gallery: prismicNewsAndEvents.data.news_gallery,
        seo_description: prismicNewsAndEvents.data.seo_description.text,
    };

    return (
        <>
            <Seo 
                title={ `${ newsItem.news_title } | P.O.W Ignaś i Helenka w Kaliskach` } 
                description={ `${ newsItem.seo_description }`}
            />

            <PageLayout
                location={ location }
                isPageScrollLocked={ isPageScrollLocked }
            >
                <Section>
                    <NewsItem 
                        newsItemData={ newsItem } 
                        showTrimmedText={ false }
                        showGallery
                        backButton
                    />
                </Section>
            </PageLayout>
        </>
    );
};

export const query = graphql`
    query NewsQuery($uid: String) {
        allPrismicNewsAndEvents {
            nodes {
                uid
            }
        }
        prismicNewsAndEvents(uid: { eq: $uid}) {
            first_publication_date
            data {
                news_date
                seo_description {
                    text
                }
                news_title {
                    text
                }
                news_text {
                    text
                }
                news_main_image {
                    alt
                    fluid {
                        src
                    }
                }
                news_gallery {
                    news_gallery_image {
                        alt
                        gatsbyImageData
                    }
                }
            }
        }
    }  
`;

export default SingleNewsPage;
